import React, { useEffect } from "react";
import "./footer.css";

const Footer: React.FC<{}> = () => {
  const backToTop = () => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <footer className="footer" data-test-id="footer">
        <div className="footer-area bg-black pd-top-95">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <div className="widget">
                  <h5 className="widget-title has-bottom-line">
                    درباره مجمع فعالان
                  </h5>
                  <div className="widget_about">
                    <p>
                      مجمع فعالان چهل باغتوی پشی متشکل از مردم قریه چهل باغتوی
                      پشی میباشد که با همدیگر اتحاد نموده و برای بازسازی و آبادی
                      قریه چهل باغتوی پشی و هم چنان کمک به مریضان و مستمندان کمر
                      همت را بسته اند.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-4">
                <div className="widget">
                  <h5 className="widget-title has-bottom-line">لینک های مهم</h5>
                  <ul className="contact_info_list">
                    <li>
                      <a href="#under-construction-projects">
                        <i className="fa fa-arrow-left" />
                        پروژه های تحت کار
                      </a>
                    </li>
                    <li>
                      <a href="#construction">
                        <i className="fa fa-arrow-left" />
                        لیست بازسازی ها
                      </a>
                    </li>
                    <li>
                      <a href="#sport">
                        <i className="fa fa-arrow-left" />
                        تورنمنت والیبال
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-sm-4">
                <div className="widget">
                  <h5 className="widget-title has-bottom-line">ارتباط</h5>
                  <ul className="contact_info_list">
                    <li>
                      <i className="fa fa-map-marker" />
                      قریه چهل باغتوی پشی
                    </li>
                    <li>
                      <i className="fa fa-phone" /> 798241674+
                    </li>
                    <li>
                      <i className="fa fa-envelope-o" />
                      info@pashe.org
                    </li>
                  </ul>
                </div>
              </div>

              <div className="back-to-top-wrapper">
                <div className="back-to-top" onClick={backToTop}>
                  {/* <span> */}
                  <i className="fa fa-arrow-up" /> {/* </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom text-center">
          <p>
            Designed and Developed by{" "}
            <a target="_blank" href="https://abdullatif.portfolio.vercel.app">
              Abdul Latif "Erfan"
            </a>
          </p>
        </div>
      </footer>
    </>
  );
};
export default Footer;
