import axios from "axios";
import { axiosResponseTypes } from "../types/Types";
import {
  APIENDPOINT,
  youtubeAPIKey,
  youtubePlayListId,
} from "../utils/baseURLs";

/**
 * ================================== SLIDER SECTION ========================
 * Description:     Get Slider Data
 * HTTP Method:     GET
 * @Returns:        {object}: returns a list of sliders
 */
export const fetchSlidersData = async () => {
  const response = await axios
    .get<axiosResponseTypes, any>(`${APIENDPOINT}/sliders`)
    .then((response) => response.data);
  return response;
};

/**
 * ================================== PROJECT SECTION ========================
 * Description:     Get Current Projects
 * HTTP Method:     GET
 * @Returns:        {object}: returns a list of projects
 */
export const fetchProjects = async () => {
  const response = await axios
    .get<axiosResponseTypes, any>(`${APIENDPOINT}/projects`)
    .then((response) => response.data);
  return response;
};

/**
 * ================================== CONSTRUCTION SECTION ========================
 * Description:     Get Construction Lists
 * HTTP Method:     GET
 * @Returns:        {object}: returns a list of constructions
 */
export const fetchContructionList = async (
  page: number,
  size: number,
  sub_category: number | any
) => {
  const filterOption = sub_category ? `&sub_category=${sub_category}` : "";
  const response = await axios
    .get<axiosResponseTypes, any>(
      `${APIENDPOINT}/constructions?page=${page}&size=${size}${filterOption}`
    )
    .then((response) => response.data);
  return response;
};

/**
 * ================================== Get Volleyball Data ========================
 * Description:     Get list of volleyball or sports
 * HTTP Method:     GET
 * @Returns:        {object}: returns a list of sports
 */

export const fetchSports = async () => {
  const response = await axios
    .get<axiosResponseTypes, any>(`${APIENDPOINT}/sports`)
    .then((response) => response.data);
  return response;
};

/**
 * ================================== Get Biography Data ========================
 * Description:     Get list of biography
 * HTTP Method:     GET
 * @Returns:        {object}: returns a list of bio
 */

export const getBioGraphy = async () => {
  const response = axios
    .get<axiosResponseTypes, any>(`${APIENDPOINT}/biography`)
    .then((response) => response);
  return response;
};

/**
 * ================================== Get Gallery Data ========================
 * Description:     Get list of biography
 * HTTP Method:     GET
 * @Returns:        {object}: returns a list of bio
 */

export const getGallery = async () => {
  const response = axios
    .get<axiosResponseTypes, any>(`${APIENDPOINT}/gallery`)
    .then((response) => response);
  return response;
};

/**
 * ================================== Get Posts by Data ========================
 * Description:     Get a post by id
 * HTTP Method:     GET
 * @Returns:        {object}: returns lists of post and related posts
 */

export const fetchPostsById = async (id: number) => {
  const response = axios
    .get<axiosResponseTypes, any>(`${APIENDPOINT}/fetchPostsById/${id}`)
    .then((response) => response);
  return response;
};

/**
 * ================================== Search by Keyword ========================
 * Description:     Get posts by searched keywords
 * HTTP Method:     GET
 * @Returns:        {object}: returns a list of posts
 */
export const SearchByKeywords = async (
  searchTerm: string,
  pageNumber: number,
  recordsPerPage: number
) => {
  const fullURL = `${APIENDPOINT}/search`;
  console.log(searchTerm);
  const response = await axios
    .get<axiosResponseTypes, any>(fullURL, {
      params: {
        q: searchTerm,
        perPage: recordsPerPage,
        page: pageNumber,
      },
    })
    .then((response) => response.data);
  return response;
};

/**
 * ================================== Youtube Playlist ========================
 * Description:     Get Youtube Playlist
 * HTTP Method:     GET
 * @Returns:        {object}: returns lists of vidoes
 */

export const fetchYoutubePlayList = async () => {
  const youtubeURL = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${youtubePlayListId}&key=${youtubeAPIKey}&maxResults=20`;

  const response = axios.get<any>(youtubeURL).then((response) => response);
  return response;
};
