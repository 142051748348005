import React from "react";
import { SearchedResultAxiosTypes } from "../../../types/Types";
import { Link } from "react-router-dom";
import { baseURL } from "../../../utils/baseURLs";
export const SearchedList: React.FC<SearchedResultAxiosTypes> = ({
  id,
  title,
  description,
  year,
  image,
  category,
}: SearchedResultAxiosTypes) => {
  return (
    <div
      className="col-md-6 col-sm-6 col-xs-12"
      data-test-id="search-post-wrapper"
    >
      <div className="search-post-wrapper">
        <div className="col-md-5 col-sm-6 col-xs-6 search-image-wrapper">
          <img
            loading="lazy"
            src={`${baseURL}images/${image}`}
            alt={title}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "./assets/images/no_image.png";
            }}
          />
        </div>
        <div className="col-md-7 col-sm-6 col-xs-6 ">
          <div className="media-body" style={{ marginTop: "18px" }}>
            <div className="date-content">
              <i className="fa fa-clock-o" /> {year ? year : "1402"}
            </div>
            <h6 className="title">
              <Link to={`details/${id}`}>{title}</Link>
            </h6>
            <p>
              {description?.length > 100
                ? description.substring(0, 100) + "..."
                : description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
