import Layout from "../../components/frontend/layout/Layout";

import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { SearchedResultAxiosTypes } from "../../types/Types";
import { SearchByKeywords } from "../../API/FetcherData";
import { SearchedPageSkeleton } from "../../components/frontend/search_components/SearchedSkeleton";
import { SearchedList } from "../../components/frontend/search_components/SearchedList";
import { useSearchContext } from "../../context/SearchContext";
import { SearchLoader } from "../../components/loader/search-loader/SearchLoader";
import Button from "../../components/frontend/form_elements/Button";

const SearchedResultPage: React.FC<{}> = () => {
  const { searchTerm, setSearchTerm } = useSearchContext();

  // ----------- ُSearching with Debouncing --------
  const debouncedSearchInputChange = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      setLoading(false);
    }, 1000),
    []
  );
  const [searchValue, setSearchValue] = useState(searchTerm || "");
  const [loading, setLoading] = useState(false);
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = event.target.value;
    setLoading(true);
    setSearchValue(value);
    debouncedSearchInputChange(value);
  };

  // ----------- get Data --------
  const [pageNumber, setPageNumber] = useState(1); // start from first page at the first time
  const recordsPerPage = 6;

  useEffect(() => {
    setPageNumber(1); // for each search term, page number should be started from 1
    refetch();
  }, [searchTerm]);

  const {
    isLoading,
    data: searchedResult,
    isError,
    error,
    isFetching,
    refetch,
  } = useQuery(
    ["searched-data", pageNumber],
    () => SearchByKeywords(searchTerm, pageNumber, recordsPerPage),
    {
      keepPreviousData: true, // keep previous data till getting the new data
      staleTime: 300000, // for 5 minutes the data should be in stale time
      refetchOnWindowFocus: false,
    }
  );

  if (isError)
    return (
      <div className="errorMessage">
        Error Occured :{error instanceof Error && <h6>{error?.message}</h6>}
      </div>
    );

  const previousButtonHandler = () => {
    setPageNumber((page) => page - 1);
  };
  const nextButtonHandler = () => {
    setPageNumber((page) => page + 1);
  };

  return (
    <Layout>
      <section
        className="section searched-section"
        data-test-id="search-wrapper"
      >
        <div className="searchedpage-wrapper">
          <div className="container">
            {/* --- search --- */}
            <div className="searchpage-search-wrapper">
              <input
                type="text"
                value={searchValue}
                onChange={handleSearchInputChange}
                placeholder="جستجو ..."
              />
              <Button
                type="submit"
                className={`submit-btn ${loading ? "search-loading" : null}`}
              >
                {!loading ? <i className="fa fa-search" /> : <SearchLoader />}
              </Button>
            </div>

            {/* --- / search --- */}
            <div className="col-12 mesagebox">
              {searchedResult?.total > 0 ? (
                <>
                  {searchedResult?.total} موضوع مرتبط یافت گردید برای جستجوی "
                  {searchTerm}"
                </>
              ) : null}
            </div>

            <div className="col-12 ">
              <div className="row">
                {isLoading || isFetching ? (
                  SearchedPageSkeleton()
                ) : searchedResult?.total > 0 ? (
                  searchedResult?.data.map(
                    ({
                      id,
                      title,
                      description,
                      year,
                      image,
                      category,
                    }: SearchedResultAxiosTypes) => (
                      <SearchedList
                        key={title}
                        id={id}
                        title={title}
                        description={description}
                        image={image}
                        year={year}
                        category={category}
                      />
                    )
                  )
                ) : (
                  <div className="d-flex center width-100 m-t-20 m-b-20">
                    <h4> یافت نگردید</h4>
                  </div>
                )}
              </div>
            </div>

            <div className="col-12 next-prev-btn m-t-20">
              {searchedResult?.total > recordsPerPage && (
                <>
                  <Button
                    className="btn prev"
                    onClick={previousButtonHandler}
                    disabled={pageNumber === 1}
                  >
                    قبلی
                  </Button>
                  <div className="pageNumber">{pageNumber}</div>
                  <Button
                    className="btn next"
                    onClick={nextButtonHandler}
                    disabled={pageNumber === searchedResult?.last_page}
                  >
                    بعدی
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default SearchedResultPage;

function debounce<T extends (...args: any[]) => any>(func: T, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
