import Button from "../../form_elements/Button";
import React, { useCallback, useState } from "react";
import { useSearchContext } from "../../../../context/SearchContext";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { SearchLoader } from "../../../loader/search-loader/SearchLoader";

export const SearchFormHandler: React.FC<{}> = () => {
  const navigate = useNavigate();

  /**
   * get pathname, check if pathname is search then do not navigate else navigate to search route
   */
  const { pathname } = useLocation();
  const paramValue = pathname.replace("/", "");

  const { searchTerm, setSearchTerm } = useSearchContext();

  const debouncedSearchInputChange = useCallback(
    debounce((value: string) => {
      // console.log("updated value=", value);
      if (paramValue != null && paramValue === "search") {
        if (value.trim().length > 0) {
          setSearchTerm(value);
        }
        // console.log("you are in search page");
      } else {
        // update the setSearchTerm and navigate to the search page
        if (value.trim().length > 0) {
          setSearchTerm(value);
          navigate("/search");
        }
        // console.log("you are in home page");
      }

      setLoading(false);
    }, 1000),

    []
  );
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSearchInputChange = (event) => {
    setLoading(true);
    setSearchValue(event.target.value);
    debouncedSearchInputChange(event.target.value);
  };

  return (
    <div
      className="nav-right-part nav-right-part-desktop"
      data-test-id="search-component"
    >
      <div className="menu-search-inner circle_search">
        <input
          type="text"
          value={searchValue}
          onChange={handleSearchInputChange}
          placeholder="جستجو ..."
        />
        <Button
          type="submit"
          className={`submit-btn ${loading ? "search-loading" : null}`}
        >
          {!loading ? <i className="fa fa-search" /> : <SearchLoader />}
        </Button>
      </div>
    </div>
  );
};

// function debounce(func, delay) {
//   let timeoutId;
//   return function (...args) {
//     clearTimeout(timeoutId);
//     timeoutId = setTimeout(() => {
//       func.apply(this, args);
//     }, delay);
//   };
// }

function debounce<T extends (...args: any[]) => any>(func: T, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
