import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export function SearchedPageSkeleton() {
  /**
   *  on the searchedPage 6 records will be shown on each page
   *  that is why 6 times this skeleton should be repeated till loading the data
   */
  const items = [1, 2, 3, 4, 5, 6];
  const SearchedLoadingSkeleton = items.map((number) => (
    <div className="col-md-6 col-sm-6 col-xs-12" key={number}>
      <div className="search-post-wrapper">
        <div className="col-md-5 col-sm-6 col-xs-6 search-image-wrapper used-skeleton">
          <Skeleton height="100%" width="100%" baseColor="#dedede" />
        </div>
        <div className="col-md-7 col-sm-6 col-xs-6 ">
          <div className="media-body" style={{ marginTop: "18px" }}>
            <div className="date-content">
              <Skeleton height="100%" width="50%" baseColor="#dedede" />
            </div>
            <Skeleton
              height="100%"
              width="100%"
              count={4}
              baseColor="#dedede"
            />
          </div>
        </div>
      </div>
    </div>
  ));
  return SearchedLoadingSkeleton;
}
