import "./searchLoader.css";
export const SearchLoader = () => {
  return (
    <div className="search-circle-wrapper">
      <div className="success circle" />
      <div className="icon">
        <i className="fa fa-search with_animation" />
      </div>
    </div>
  );
};
