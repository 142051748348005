import "./mega.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchSports } from "../../../../API/FetcherData";
import { ProjectListPropType } from "../../../../types/Types";
import { SportMegaMenuList } from "./SportMegaMenuList";

export const Menu: React.FC<{}> = () => {
  const [sportData, setSportData] = useState([]);
  const [isHovering, setIsHovering] = useState(false);
  const [selectedItem, setSelectedItem] = useState("1");

  // ---------------- get sport data ---------------
  const { isLoading, isError, error, data } = useQuery("sports", fetchSports, {
    staleTime: 300000, // for 5 minutes the data should be in stale time
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const cur_data = data?.data;
    const fourSportPost = cur_data?.length ? cur_data.slice(0, 4) : [];
    setSportData(fourSportPost || []);
  }, [isHovering]);

  // handle mouse hover
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleItemClick = (event) => {
    const clickedItem = event.target.closest(".menu-item");
    if (clickedItem) {
      const clickedItemId = clickedItem.getAttribute("data-id");
      setSelectedItem(clickedItemId);
    }
  };

  return (
    <>
      <ul
        className="navbar-nav menu-open main-nav"
        onClick={handleItemClick}
        data-test-id="menu"
      >
        <li
          className={`menu-item ${selectedItem === "1" ? "active" : ""}`}
          data-id="1"
        >
          <Link to="/"> صفحه اصلی </Link>
        </li>
        <li
          className={`menu-item ${selectedItem === "2" ? "active" : ""}`}
          data-id="2"
        >
          <Link to="/details/39">معرفی چهل باغتو </Link>
        </li>
        <li
          className={`menu-item ${selectedItem === "3" ? "active" : ""}`}
          data-id="3"
        >
          <Link to="/details/61"> بازسازی ها</Link>
        </li>
        <li className="hidden-md">
          <Link to="/details/47"> ورزش</Link>
        </li>

        <li className="menu-item  top-level-link">
          <a
            className="mega-menu display-md"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <span> ورزش</span>
          </a>
          <div className="sub-menu-block">
            <div className="row">
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                sportData?.map(
                  ({
                    id,
                    title,
                    description,
                    year,
                    image,
                  }: ProjectListPropType) => (
                    <SportMegaMenuList
                      id={id}
                      title={title}
                      description={description}
                      image={image}
                      year={year}
                      key={id}
                    />
                  )
                )
              )}
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};
