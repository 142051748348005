import React from "react";
import { Link } from "react-router-dom";
import { ProjectListPropType } from "../../../../types/Types";
import { baseURL } from "../../../../utils/baseURLs";

export const SportMegaMenuList: React.FC<ProjectListPropType> = ({
  id,
  title,
  description,
  year,
  image,
}: ProjectListPropType) => {
  return (
    <div className="col-3 mega-single-post" data-test-id="mega-menu">
      <Link to={`/details/${id}`}>
        <div className="mega-image-wrapper">
          <img
            loading="lazy"
            src={`${baseURL}images/${image}`}
            alt={title}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "./assets/images/no_image.png";
            }}
          />
        </div>
        <div className="mega-text-wrapper">
          <span className="mega-text">
            {title.length > 30 ? title.substring(0, 30) + "..." : title}
          </span>
        </div>
      </Link>
    </div>
  );
};
