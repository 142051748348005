/**
 * Manage URLs from this file
 */

// export const APIENDPOINT = 'http://127.0.0.1:8000/api';
// export const baseURL = "http://localhost:8000/";

export const APIENDPOINT = "https://pashe.org/website/api";
export const baseURL = "https://pashe.org/website/api/public/";
export const youtubePlayListId = "PL1ZQ11OjmqV0wqFTFemiPrxECYtc4NW7N";
export const youtubeAPIKey = "AIzaSyBPv56v30o71H99IQfjmPKMndgLpowyWWg";
