import React from "react";
import Input from "../../form_elements/Input";
import Button from "../../form_elements/Button";
import { Menu as MenuList } from "../Menu/Menu";
import { SearchFormHandler } from "./SearchFormHandler";

const Header: React.FC<{}> = () => {
  // toggle the Menu
  function toggleNav() {
    let menu_toggler = document.querySelector("#menu_toggler");
    let mobile_menu = document.querySelector("#mobile_menu");

    // add active class
    menu_toggler.classList.toggle("open");
    mobile_menu.classList.toggle("open");

    // remove open class from menu item
    const navbarNav = document.querySelector(".navbar-nav");

    const menuItemClickHandler = (event) => {
      if (event.target.matches(".menu-item a")) {
        menu_toggler.classList.remove("open");
        mobile_menu.classList.remove("open");
        console.log("clicked on menu item");
      }
    };
    navbarNav.addEventListener("click", menuItemClickHandler);
    return () => navbarNav.removeEventListener("click", menuItemClickHandler);
  }

  function searchPopup() {
    let search_popup = document.querySelector("#search-popup-form-wrapper");
    let body_overlay = document.querySelector("#body-overlay");

    // add active class
    search_popup.classList.add("active");
    body_overlay.classList.add("active");
  }

  function closeSearchPopup() {
    let search_popup = document.querySelector("#search-popup-form-wrapper");
    let body_overlay = document.querySelector("#body-overlay");

    // remove active class
    search_popup.classList.remove("active");
    body_overlay.classList.remove("active");
  }

  return (
    <>
      {/* search popup start*/}
      {/* ----------- Implementation will be next task --------- */}
      <div
        className="search-popup-form-wrapper"
        id="search-popup-form-wrapper"
        data-test-id="header"
      >
        <form action="/search" className="search-form">
          <div className="form-group">
            <Input
              type="text"
              className="form-control"
              placeholder="جستجو ..."
            />
          </div>
          <Button type="submit" className="submit-btn">
            <i className="fa fa-search" />
          </Button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        className="body-overlay"
        id="body-overlay"
        onClick={closeSearchPopup}
      />
      <header className="header">
        {/* top-header */}
        <div className="top-header-wrapper">
          <div className="skew"></div>
          {/* logo-wrapper*/}
          <div className="logo-wrapper d-none d-lg-block">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5">
                  <a className="main-logo" href="#">
                    <img src="/assets/images/logo.png" alt="Erfan Logo" />
                  </a>
                </div>
                <div className="col-xl-6 col-lg-7">
                  <div className="right-size-contact ">
                    <div className="map-link-wrapper">
                      <div className="map-link-item">
                        <div className="contact-title">
                          <span>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.google.com/maps/place/33%C2%B006'41.0%22N+67%C2%B016'01.0%22E/@33.1118782,67.2662291,507m/data=!3m1!1e3!4m4!3m3!8m2!3d33.1113889!4d67.2669444!5m2!1e2!1e4?entry=ttu"
                            >
                              لینک گوگل مپ
                            </a>
                          </span>
                          <span>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://earth.google.com/earth/d/1jZK4j3mDoc7D3E1dgcJSU2V_oI0Hxomw?usp=sharing"
                            >
                              لینک گوگل ایرت
                            </a>
                          </span>
                        </div>
                        <i className="fa fa-map-marker fa-2px"></i>
                      </div>
                    </div>

                    <div className="top-contact-wrapper">
                      <div className="map-link-item">
                        <div className="contact-title">
                          <span>798241674+</span>
                          <span>info@pashi.org</span>
                        </div>
                        <i className="fa fa-phone-square fa-2px"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* logo-wrapper */}

          {/* nave */}
          <nav className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div className="responsive-mobile-menu">
                <button
                  className="menu toggle-btn d-block d-lg-none "
                  onClick={toggleNav}
                  id="menu_toggler"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-left" />
                  <span className="icon-right" />
                </button>
              </div>

              <SearchFormHandler />
              <div className="nav-right-part nav-right-part-mobile">
                <a className="search header-search" href="#">
                  <i onClick={searchPopup} className="fa fa-search" />
                </a>
              </div>
              <div className="collapse navbar-collapse" id="mobile_menu">
                <MenuList />
              </div>
            </div>
          </nav>
          {/* End nave */}
        </div>
        {/* End top-header */}
      </header>
    </>
  );
};

export default Header;
